@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
  outline: none;
}

.modal {
  height: 100%;

  & .innerContainer {
    position: relative;
    width: 440px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .sectionTitle {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .contentContainer {
      display: flex;
      gap: 22px;
      margin-top: 22px;

      & .videoWrapper {
        flex: 1;
        & .video {
          width: 100%;
          height: 100%;
          background: $grayE450;
          border-radius: 5px;
          object-fit: cover;
        }
      }

      & .infoWrapper {
        flex: 1;

        & .jobInfo {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }

        & .sectionTitle {
          @include text_small_bold;
          margin-top: 15px;
          margin-bottom: 10px;
        }

        & .tagsContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }

        & .optionsContainer {
          margin-top: 22px;
          padding: 25px 20px;
          border-radius: 15px;
          background: $grayE425;
          display: flex;
          flex-direction: column;
          gap: 25px;
          margin-bottom: 22px;

          & .radioOption {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            & .radioTitle {
              @include text_medium_bold;
            }
          }

          & .blockReasonWrapper {
            margin-left: 30px;
            & .title {
              @include text_small_regular;
              color: $textMain;
            }
            & .reasonList {
              display: flex;
              flex-direction: column;
              gap: 12px;
              margin-top: 12px;
              & .radioOption {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                & .radioTitle {
                  @include text_small_regular;
                  color: $textMain;
                }
              }
            }
          }
        }
      }
    }
  }
}
