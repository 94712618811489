@import "../../scss/main.scss";

.wrapper {
  & .btn {
    display: flex;
    align-items: center;

    gap: 16px;
    padding: 18px 10px 18px 20px;
    border-radius: 12px;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      background: $primaryLight;
    }

    & svg {
      fill: $grayLight;
      transition: all 0.2s linear;
    }

    & p {
      @include text_small_bold;
      color: $grayLight;
    }
    & .arrow {
      width: 24px;
      height: 24px;
      fill: none;
      stroke: $grayLight;
      margin-left: auto;
    }
  }
  & .activeBtn {
    background: $primary;

    &:hover {
      opacity: 0.95;
      background: $primary;
    }
    & svg {
      fill: $white;
      transition: all 0.2s linear;
    }

    & p {
      color: $white;
    }
    & .arrow {
      width: 24px;
      height: 24px;
      fill: none;
      stroke: $white;
      margin-left: auto;
      transform: rotate(180deg);
    }
  }
}
