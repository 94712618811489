@import "../../scss/main.scss";

.input {
  position: relative;
  &__main {
    width: 100%;
    height: 110px;
    background-color: rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    padding: 15px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    caret-color: $primary;
    color: #000;
    resize: none;
    @include text_small_bold;
    &::placeholder {
      color: $deactive;
    }
    border: 1px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
    }
  }
  &:not(:last-child) {
    // margin-bottom: 18px;
  }
  span {
    display: block;
    color: $deactive;
    margin-bottom: 16px;
    @include text_x_small_medium;
  }
  p {
    position: absolute;
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}
