@import "../../../../scss/main.scss";

.videosContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
}
.loaderWrappaer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.emptyTitle {
  @include text_medium_medium;
  color: $textMain;
  margin: 50px 0;
  text-align: center;
}

.paginationContainer {
  margin-top: 30px;
  display: flex;
  gap: 8px;

  & .nextBtn {
    cursor: pointer;
    background-color: $black;
    padding: 14px 30px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    font-family: $secondary-font;
  }
}
