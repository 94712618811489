@import "../scss/vars.scss";
@import "../scss/typoghraphy.scss";
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  color: $dark;
  background-color: $white;
}

.container {
  width: 100%;
  max-width: 1400px;
  padding: 0 15px;
  margin: 0 auto;
}

.ReactModal__Overlay {
  opacity: 0;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: all 0.2s ease;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: all 0.2s ease;
}

.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast {
  background-color: $white;
  color: $dark;
}
.Toastify__close-button {
  color: $dark;
}

@media (max-width: 480px) {
  .Toastify__toast-container--top-center {
    right: 10px;
    top: 10px;
  }
  .Toastify__toast-container {
    left: initial;
  }
}
