@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  align-items: center;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 100px;
}

.modal {
  & .deleteJobPostingInner {
    position: relative;
    width: 474px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .subTitle {
      margin-top: 15px;
      @include text_small_regular;
      color: $deactive;
    }

    & .reasonList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 15px;
      margin-bottom: 15px;
      & .radioOption {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        & .radioTitle {
          @include text_small_regular;
          color: $textMain;
        }
      }
    }
  }
  & .innerContainer {
    position: relative;
    width: 920px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .header {
      display: flex;
      align-items: center;
      margin-top: 22px;

      & .infoBlock {
        flex: 1;

        & .name {
          @include desktop_h4;
          margin-bottom: 10px;
        }
      }
    }

    & .tagsContainer {
      margin-top: 22px;
      display: flex;
      align-items: center;
      gap: 30px;

      & .calendar {
        margin-left: auto;
      }
    }
    & .contentContainer {
      display: flex;
      margin-top: 22px;
      gap: 22px;

      & .descriptionBlock {
        flex: 1;

        & .sectionTitle {
          @include desktop_h5;
        }

        & .description {
          @include text_small_regular;
          color: $textMain;
          margin-top: 5px;
        }
      }

      & .videoBlock {
        width: 290px;

        & .sectionTitle {
          @include desktop_h5;
        }

        & .video {
          margin-top: 5px;
          background: $grayE450;
          border-radius: 5px;
          height: 412px;
          width: 290px;
          object-fit: cover;
        }
      }
    }
  }
}
