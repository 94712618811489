@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 30%;

  & .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 12px;

    & .title {
      @include text_medium_bold;
      flex: 1;
    }
    & .percent {
      @include text_x_small_medium;
    }
  }
}
