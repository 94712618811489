@import "../../scss/main.scss";

.activeWrapper {
  background: #e7deff;
  &:hover {
    background: #e7deff !important;
  }
}
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 16px;
  transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    background: $grayVeryLight;
  }

  & .avatar {
    min-width: 44px;
    max-width: 44px;
    min-height: 44px;
    max-height: 44px;
    border-radius: 100px;
    overflow: hidden;
  }

  & .contentContainer {
    flex: 1;
    overflow: hidden;

    & .name {
      @include text_medium_bold;
    }

    & .message {
      display: block;
      @include text_x_small_regular;
      color: $grayDark;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  & .date {
    @include text_x_small_bold;
    color: $grayDark;
  }
}
