@import "../../scss/main.scss";

.wrapper {
  background: #f9f9f9;
  flex: 1;
  border-radius: 10px;
  padding: 0 16px 15px 16px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar {
    background-color: $white;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $grayE4;
    border-radius: 100px;
  }

  & .pulseLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & .loaderPagination {
    margin: 20px auto;
  }
}
