@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  align-items: center;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 100px;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 700px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .header {
      display: flex;
      align-items: center;
      margin-top: 22px;

      & .infoBlock {
        flex: 1;

        & .titleRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          & .name {
            @include desktop_h4;
            margin-bottom: 10px;
          }

          & .showJobBtn {
            display: flex;
            align-items: center;
            @include text_small_bold;

            color: $primary;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
              opacity: 0.8;
            }
            & svg {
              stroke: $primary;
              transform: rotate(-90deg);
            }
          }
        }

        & .userRow {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    & .contentContainer {
      display: flex;
      gap: 22px;

      margin-top: 22px;

      & .cvWrapper {
      }
      & .descriptionBlock {
        flex: 1;
        & .sectionTitle {
          @include desktop_h5;
          margin-bottom: 10px;
          margin-top: 22px;
        }

        & .tagsContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }
      }

      & .videoBlock {
        width: 290px;

        & .sectionTitle {
          @include desktop_h5;
        }

        & .video {
          margin-top: 5px;
          background: $grayE450;
          border-radius: 5px;
          height: 412px;
          width: 290px;
          object-fit: cover;
        }
      }
    }
  }
}
