@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 460px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & h5 {
      @include desktop_h5;
      text-align: center;
    }

    & h1 {
      font-size: 40px;
      line-height: 50px;
      color: $primary;
      font-family: $primary-font;
      font-weight: 700;
      margin: 30px 0;
      text-align: center;
    }
    & .btnContainer {
      margin-top: 30px;
      display: flex;
      gap: 20px;
    }
  }
}
