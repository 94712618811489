@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  align-items: center;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 100px;
}

.modal {
  max-height: 100vh;
  & .innerContainer {
    position: relative;
    width: 700px;
    margin-top: 50px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .sectionTitle {
      @include desktop_h5;
    }

    & .type {
      margin-top: 22px;
      @include desktop_h4;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .infoRow {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 22px;

      & .reporterWrapper {
        flex: 1;
        & .title {
          @include text_x_small_bold;
          color: $deactive;
          margin-bottom: 5px;
        }
      }

      & .reportedWrapper {
        flex: 1;
        & .title {
          @include text_x_small_bold;
          color: $deactive;
          margin-bottom: 5px;
        }
      }
      & .dateWrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 5px;
        gap: 5px;
        & svg {
          fill: $primary;
        }

        & p {
          @include text_small_regular;
        }
      }
    }

    & .description {
      margin-top: 5px;
      margin-bottom: 20px;
      @include text_small_regular;
      color: $textMain;
    }

    & .attachment {
      width: 100px;
      height: 120px;
      object-fit: cover;
      border-radius: 5px;
      margin-top: 10px;
    }
    & .optionsContainer {
      margin-top: 22px;
      padding: 25px 20px;
      border-radius: 15px;
      background: $grayE425;
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin-bottom: 22px;

      & .radioOption {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        & .radioTitle {
          @include text_medium_bold;
        }
      }

      & .blockReasonWrapper {
        margin-left: 30px;
        & .title {
          @include text_small_regular;
          color: $textMain;
        }
        & .reasonList {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-top: 12px;
          & .radioOption {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            & .radioTitle {
              @include text_small_regular;
              color: $textMain;
            }
          }
        }
      }
    }
  }
}
