@import "../../scss/main.scss";

.checked {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background: $primary;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    color: $white;
  }
}

.unChecked {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  background: $white;
  border: 2px solid $grayE4;
}
