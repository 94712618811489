@import "../../scss/main.scss";

.wrapper {
  position: relative;
  width: 195px;
  height: 282px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  & .shadow {
    position: absolute;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $grayE450;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
}
