@import "../../../scss/main.scss";

.wrapper {
  max-width: 100%;
  padding-bottom: 30px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
    }
  }
  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;

    & .sortSwitchersWrapper {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    & .searchContainer {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }

    & .sectionHeader {
      display: flex;
      align-items: center;
      padding-bottom: 17px;
      border-bottom: 1px solid $shadow;

      & .reporterBlock {
        flex: 1;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .statusBlock {
        flex: 0.9;
        margin-left: 20px;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }
      & .tagsBlock {
        flex: 0.8;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .dateBlock {
        flex: 0.6;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        gap: 3px;
        cursor: pointer;

        & svg {
          transition: all 0.2s linear;
          stroke: $dark;
        }
      }
      & .dateBlockActive {
        & svg {
          transition: all 0.2s linear;
          transform: rotate(180deg);
        }
      }
    }
    & .reportsContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }

    & .emptyTitle {
      text-align: center;
      margin: 50px 0px;
      @include text_medium_medium;
      color: $deactive;
    }
    & .paginationContainer {
      margin-top: 30px;
      display: flex;
      gap: 8px;

      & .nextBtn {
        cursor: pointer;
        background-color: $black;
        padding: 14px 30px;
        border-radius: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        font-family: $secondary-font;
      }
    }
  }
}
