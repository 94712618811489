@import "../../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .backBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;

      & svg {
        stroke: $dark;
      }

      & span {
        @include desktop_h5;
      }
    }
    & .btnWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;
    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;
    height: 100%;
    overflow: hidden;
    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    }
    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .info {
        display: flex;
        align-items: center;
        gap: 20px;

        & .avatar {
          width: 100px;
          height: 100px;
          border-radius: 100px;
          object-fit: cover;
        }

        & .nameWrapper {
          & .name {
            @include desktop_h4;
          }

          & .location {
            @include text_small_regular;
            color: $grayDark;
          }
        }
      }

      & .followersStatsWrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: auto;
        margin-right: 50px;
        & .divider {
          width: 1px;
          height: 25px;
          background-color: $grayDark;
        }
        & .block {
          & .counter {
            @include desktop_h4;
          }

          & .subtitle {
            @include text_small_regular;
            color: $grayDark;
          }
        }
      }
      & .socialbtnWrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        & .socialBtn {
          cursor: pointer;
        }
      }
    }

    & .tabWrapper {
      display: flex;
      gap: 2px;
      margin-top: 25px;
    }

    & .rowContainer {
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      gap: 25px;
      height: calc(100% - 140px);
      overflow: hidden;
      & .chatsWrapper {
        display: flex;
        flex-direction: column;
        width: 36%;
        & .chatsContainer {
          margin-top: 15px;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          & .emptyTitle {
            @include text_medium_medium;
            text-align: center;
            margin-top: 20px;
            color: $grayDark;
          }
          &::-webkit-scrollbar-track {
          }

          &::-webkit-scrollbar {
            background-color: $white;
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $grayE4;
            border-radius: 100px;
          }
        }
      }
    }
  }
}
