@import "../../scss/main.scss";

.wrapper {
  position: relative;

  & span {
    display: block;
    color: $deactive;
    margin-bottom: 16px;
    @include text_x_small_medium;
  }
  .selector {
    background: #ececec;
    padding: 18px 23px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    min-width: 235px;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      background: #e2e2e2;
    }

    & .title {
      flex: 1;
      @include text_small_bold;
    }
    & svg {
      transition: all 0.2s linear;

      stroke: $dark;
    }
  }

  & .activeSelector {
    & svg {
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
  }

  & .dataList {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: #f6f6f6;
    border-radius: 12px;
    margin-top: 2px;
    z-index: 10;
    & .itemlist {
      padding: 18px 23px;
      @include text_small_medium;
      cursor: pointer;
      transition: all 0.2s linear;

      &:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
      }

      &:hover {
        background-color: #ececec;
      }
    }
  }
}
