//fonts
$primary-font: "EudoxusSans", "sans-serif";
$secondary-font: "Inter", "sans-serif";

//colors
$white: #ffffff;
$black: #000000;
$textMain: #54585f;
$primary: #7d4afb;
$primaryLight: #e7deff;
$grayE4: #e4e4e4;
$grayE450: #e4e4e432;
$grayE425: #e4e4e419;
$grayDark: #8a93a0;
$overlay: #1b1d2146;
$grayLight: #808191;
$grayVeryLight: rgba(245, 246, 248, 1);
$deactive: #b2b3bd;
$shadow: #e3e6ec;
$dark: #11142d;
$red: #ff3c3c;
$green: #63cd5a;
$orange: #ff754c;
