@import "../../../scss/main.scss";

.wrapper {
  max-width: 100%;
  padding-bottom: 30px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
    }
  }
  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;

    & .sortSwitchersWrapper {
      display: flex;
      align-items: center;
      gap: 2px;
      position: relative;
      & .filterContainer {
        margin-left: auto;

        & .filterBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $white;
          width: 48px;
          height: 48px;
          border-radius: 100px;
          box-shadow: 0px 15px 80px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          transition: all 0.2s linear;
          & svg {
            stroke: $dark;
          }
        }
        & .activeFilterBtn {
          background: $primary;

          & svg {
            stroke: $white;
          }
        }

        & .filterWrapper {
          position: absolute;
          top: 70px;
          right: 0;
          padding: 25px 30px;
          border-radius: 24px;
          background: $white;
          box-shadow: 0px 15px 80px rgba(0, 0, 0, 0.1);
          z-index: 10;
          width: 300px;

          & .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .title {
              @include text_large_bold;
            }

            & .clearFilterBtn {
              @include text_small_bold;
              color: $primary;
              cursor: pointer;
              transition: all 0.2s linear;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          & .sectionTitle {
            @include text_small_bold;
            color: $deactive;
            margin-top: 20px;
          }

          & .list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 16px 0;
          }

          & .divider {
            margin-top: 20px;
            height: 1px;
            width: 100%;
            background: $shadow;
          }

          & .btndivider {
            margin-top: 20px;
            height: 1px;
            width: 100%;
            background: $shadow;
            margin-bottom: 20px;
          }
        }
      }
    }

    & .searchContainer {
      display: flex;
      align-items: center;
      margin: 28px 0;
    }

    & .sectionHeader {
      display: flex;
      align-items: center;
      padding-bottom: 17px;
      border-bottom: 1px solid $shadow;

      & .reporterBlock {
        flex: 1;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        margin-left: 24px;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }
      & .typeBlock {
        flex: 0.5;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .categoryBlock {
        flex: 0.65;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .reportedOnBlock {
        flex: 0.65;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .dateBlock {
        flex: 0.65;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        gap: 3px;
        cursor: pointer;

        & svg {
          transition: all 0.2s linear;
          stroke: $dark;
        }
      }
      & .dateBlockActive {
        & svg {
          transition: all 0.2s linear;
          transform: rotate(180deg);
        }
      }
    }
    & .reportsContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
    & .emptyTitle {
      text-align: center;
      margin: 50px 0px;
      @include text_medium_medium;
      color: $deactive;
    }
    & .paginationContainer {
      margin-top: 30px;
      display: flex;
      gap: 8px;

      & .nextBtn {
        cursor: pointer;
        background-color: $black;
        padding: 14px 30px;
        border-radius: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        font-family: $secondary-font;
      }
    }
  }
}
