@import "../../scss/main.scss";

.wrapper {
  padding: 5px 0px;

  &:not(:last-child) {
    border-bottom: 1px solid $shadow;
  }
  & .name {
    @include text_medium_bold;
  }
  & .subtitle {
    @include text_small_regular;
    color: $textMain;
  }
  & .date {
    @include text_x_small_regular;
    color: $grayDark;
  }
}
