@import "../../../../scss/main.scss";

.searchWrapper {
  margin: 22px 0px;
}

.employmentHistory {
  margin-top: 20px;

  & .title {
    @include desktop_h5;
  }

  & .sectionHeader {
    display: flex;
    align-items: center;
    padding-bottom: 17px;
    border-bottom: 1px solid $shadow;
    margin-top: 10px;

    & .companyBlock {
      flex: 1;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      margin-left: 24px;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }
    & .workBlock {
      flex: 0.8;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }
    & .timeBlock {
      flex: 0.8;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }
  }
  & .jobContainer {
    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40vh;
    }
  }
  & .paginationContainer {
    margin-top: 30px;
    display: flex;
    gap: 8px;

    & .nextBtn {
      cursor: pointer;
      background-color: $black;
      padding: 14px 30px;
      border-radius: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      font-family: $secondary-font;
    }
  }
}
.emptyTitle {
  @include text_medium_medium;
  color: $textMain;
  text-align: center;
  margin: 50px 0px;
}
