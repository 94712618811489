@import "../../scss/main.scss";

.wrapper {
  width: 20px;
  height: 20px;
  border: 2px solid $grayE4;
  border-radius: 100px;
  background: transparent;
  cursor: pointer;
}

.checkedWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: $primary;
  border: none;

  & .checkedIndicator {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background: $white;
  }
}
