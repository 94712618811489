@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    opacity: 0.8;
  }

  & .avatar {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    object-fit: cover;
  }

  & .name {
    @include text_medium_bold;
  }
}
