@import "../../scss/main.scss";
.wrapper {
  margin-top: 25px;

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $shadow;
    padding-bottom: 15px;
    & .nameBlock {
      flex: 1;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }
    & .dateBlock {
      flex: 1;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      gap: 3px;
      cursor: pointer;

      & svg {
        transition: all 0.2s linear;
        stroke: $dark;
      }
    }
    & .dateBlockActive {
      & svg {
        transition: all 0.2s linear;
        transform: rotate(180deg);
      }
    }
  }

  & .listContainer {
    & .emptyTitle {
      text-align: center;
      margin: 50px 0px;
      @include text_medium_medium;
      color: $deactive;
    }
  }

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
}
