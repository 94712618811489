@import "../../scss/main.scss";

.wrapper {
  padding: 3px 10px 4px 10px;
  border-radius: 8px;
  width: max-content;

  & span {
    @include text_x_small_bold;
    color: $white;
  }
}

.problemWrapper {
  background: $orange;
}

.userWrapper {
  background: $deactive;
}
