@import "../../scss/main.scss";

.wrapper {
  display: flex;

  & svg {
    fill: $primary;
  }

  & span {
    @include text_small_regular;
    color: $textMain;
    margin-left: 5px;
  }
}
