@import "../../scss/main.scss";
.leftWrapper {
  align-self: flex-start;
  & .image {
    width: 50%;
    border-radius: 20px;
  }
}

.rightWrapper {
  align-self: flex-end;
  & .image {
    width: 50%;
    margin-left: auto;
    border-radius: 20px;
  }
}

.sameAuthorWrapper {
  margin-bottom: 2px;
}

.differentAuthorWrapper {
  margin-bottom: 10px;
}
.infoMessage {
  @include text_medium_regular;
  text-align: center;
  color: $grayDark;
  margin-top: 15px;
  margin-bottom: 15px;
}

.offerWrapper {
  border: 2px solid black;
  border-radius: 20px;
  max-width: 400px;
  padding: 15px;
  align-self: center;
  & .offerFooter {
  }
  & .offerTitle {
    @include text_medium_bold;
    margin-bottom: 10px;
  }

  & .iconTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    & svg {
      fill: $primary;
    }

    & .iconTitle {
      @include text_small_bold;
    }
  }

  & .iconTagRowContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    & .iconTag {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      & svg {
        fill: $primary;
      }

      & .iconTitle {
        @include text_small_bold;
      }
    }
  }

  & .offerDescription {
    margin: 10px 0;
    @include text_small_regular;
    word-wrap: break-word;
  }
}

.wrapper {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  max-width: 60%;

  & .avatar {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 100px;
    object-fit: cover;
  }

  & .leftMessageWrapper {
    background: $primary;
    position: relative;
    & .message {
      @include text_small_regular;
      color: $white;
      margin-right: 30px;
      margin-bottom: 3px;
    }

    & .time {
      @include text_x_small_regular;
      font-size: 10px;
      line-height: 14px;
      position: absolute;
      right: 14px;
      bottom: 4px;
      color: $white;
      opacity: 0.5;

      & svg {
        fill: $primary;
      }
    }
  }

  & .rightMessageWrapper {
    background: #e2e4e7;
    position: relative;
    min-width: 70px;

    & .message {
      @include text_small_regular;
      margin-bottom: 3px;
    }
    & .time {
      display: flex;
      align-items: center;
      @include text_x_small_regular;
      font-size: 10px;
      line-height: 14px;
      position: absolute;
      right: 5px;
      bottom: 4px;
      color: $grayDark;
      & svg {
        fill: $primary;
      }
    }
  }

  & .messageWrapper {
    padding: 10px 15px 12px 15px;
    border-radius: 8px;
  }
}
