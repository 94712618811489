@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 12px;
  gap: 10px;
  background: linear-gradient(
    78.44deg,
    rgba(136, 38, 255, 0.1) 0%,
    rgba(66, 255, 232, 0.1) 100%
  );
  border-radius: 15px;
  max-width: 314px;
  cursor: pointer;
  color: $dark;
  text-decoration: none;

  & svg {
    min-width: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  & .title {
    max-width: 220px;
    @include text_medium_bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
  }

  & .date {
    @include text_x_small_bold;
    color: $grayDark;
    text-decoration: none;
  }
}
