@import "../../scss/main.scss";

.wrapper {
  border-bottom: 1px solid $shadow;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    background-color: $grayVeryLight;
  }

  & .avatarWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 24px;
    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
    }

    & .name {
      @include text_medium_bold;
    }
  }

  & .industryWrapper {
    flex: 0.8;
    & p {
      @include text_small_regular;
      color: $textMain;
    }
  }
  & .locationWrapper {
    flex: 0.8;

    & p {
      @include text_small_regular;
      color: $textMain;
    }
  }
  & .sizeWrapper {
    flex: 0.5;

    & p {
      @include text_small_regular;
      color: $textMain;
    }
  }
  & .dateWrapper {
    flex: 0.8;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      @include text_small_regular;
    }

    & .arrowBtn {
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        transform: rotate(-90deg);
      }
    }
  }
}
