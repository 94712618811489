@import "../../scss/main.scss";

.wrapper {
  border-radius: 16px;
  background: rgba(228, 228, 228, 0.2);
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 447px;
  width: 100%;

  & svg {
    fill: $dark;
    margin-left: 18px;
  }

  & .input {
    @include text_small_bold;

    background: transparent;
    padding: 18px 0px;
    flex: 1;
    outline: none;
    border: none;

    &:focus {
      outline: none;
      border: none;
    }

    &:active {
      outline: none;
      border: none;
    }
  }
}
