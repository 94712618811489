@import "../../../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  & h5 {
    @include desktop_h5;
    margin-top: 20px;
  }

  & .description {
    @include text_small_regular;
    color: $textMain;
    margin-top: 5px;
  }

  & a {
    text-decoration: none;
    @include text_small_regular;
    color: $primary;
    margin-top: 5px;
  }
}
