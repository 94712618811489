@import "../../scss/main.scss";

.wrapper {
  border-bottom: 1px solid $shadow;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    background-color: $grayVeryLight;
  }
  & .avatarWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 15px;
    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
      object-fit: cover;
    }

    & .name {
      @include text_medium_bold;
    }
  }

  & .tagsWrapper {
    flex: 0.8;
    display: flex;
    gap: 5px;
  }
  & .dateWrapper {
    flex: 0.6;
    display: flex;
    gap: 5px;
  }

  & .statusWrapper {
    flex: 0.9;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      @include text_small_regular;
    }

    & .playVideoBtn {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: $primaryLight;
      border-radius: 12px;
      padding: 8px 10px;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover {
        opacity: 0.8;
      }

      & svg {
        fill: $primary;
      }

      & span {
        @include text_small_bold;
        color: $primary;
      }
    }

    & .btnWrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      & .approveBtn {
        width: 36px;
        height: 36px;
        background: $green;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s linear;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      & .declineBtn {
        width: 36px;
        height: 36px;
        background: $red;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s linear;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
