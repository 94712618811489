@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.modal {
  max-height: 100vh;
  margin: 0;
  padding: 0;
  & .innerContainer {
    position: relative;
    width: 726px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .sectionTitle {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .videoWrapper {
      display: flex;
      justify-content: center;

      & .video {
        width: 434px;
        height: 75vh;
        max-height: 737px;
        background: $grayE4;
        border-radius: 5px;
        object-fit: contain;
      }
    }

    & .infoWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 22px;
      gap: 15px;

      & .row {
        display: flex;
        gap: 10px;
      }

      & .tagsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
}
