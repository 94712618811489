@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid $shadow;
  position: relative;

  & .nameWrapper {
    flex: 0.8;
    margin-left: 24px;
    display: flex;
    align-items: center;

    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
      margin-right: 15px;
    }

    & .name {
      @include text_medium_bold;
    }
  }

  & .emailWrapper {
    flex: 0.8;

    & .email {
      @include text_small_regular;
    }
  }
  & .roleWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
      stroke: $dark;
    }

    & .dotsMenu {
      cursor: pointer;
    }
  }

  .menuWrapper {
    position: absolute;
    right: -20px;
    top: 60px;
    width: 135px;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
    background: #fff;
    z-index: 99;
    padding: 10px;

    & .menuButton {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 6px 8px;
      border-radius: 10px;
      transition: all linear 0.2s;
      cursor: pointer;

      & .editIcon {
        fill: $dark;
      }

      & .deleteIcon {
        fill: $red;
      }

      & .deleteLoader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background: #f8f8f8;
      }

      & span {
        @include text_small_bold;
        margin-left: 10px;
        color: $dark;
      }
    }
  }
}
