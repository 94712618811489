@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  height: 300px;
}

.chartsControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 28px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
}

.activeControlsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 12px;
  height: 36px;
  background-color: $primary;
  cursor: pointer;
}

.tooltipWrapper {
  padding: 5px 15px;
  border-radius: 16px;
  background: $dark;
  border: none;
  outline: none;
  &:focus {
    outline: none;
    border: none;
  }
  &:active {
    outline: none;
    border: none;
  }

  & .label {
    @include text_x_small_bold;
    font-size: 12px;
    color: $white;
  }

  & .data {
    @include text_x_small_bold;
    color: $white;
    text-align: center;
  }
}

.contorlsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 12px;
  height: 36px;
  cursor: pointer;
}

.activeControlsBtnText {
  font-family: $primary-font;
  color: $white;
}
